import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Slices } from '../slices/slices';

const Project = (props) => {
  const { location, data } = props;
  const siteName = data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteName;
  const dataPage = data && data.prismicProject && data.prismicProject.data;
  const dataSlices = dataPage && dataPage.body;
  const firstSlice = dataSlices && dataSlices[0] && dataSlices[0].slice_type;
  const isHero = firstSlice === 'hero_image' || firstSlice === 'hero_video';

  return (
    <Layout location={location} isHero={isHero}>
      <SEO
        title={`${dataPage && dataPage.title && dataPage.title.text} | ${siteName}`}
        desc={dataPage?.description?.text}
      />
      <Slices data={dataSlices} />
    </Layout>
  );
};

export default Project;

//           ...ProjectFeatures
// above was in body, need help with it
// Fragment "ProjectFeatures" cannot be spread here 
// as objects of type "PrismicProjectDataBodySlicesType" 
// can never be of type "PrismicServiceDataBodyFeatures"

export const Query = graphql`
  query($uid: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    prismicProject(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...ProjectAccordion
          ...ProjectContentDisplay
          ...ProjectForm
          ...ProjectHeroImage
          ...ProjectHeroVideo
          ...ProjectImageSlider
          ...ProjectImageText
          ...ProjectList
          ...ProjectProcess
          ...ProjectQuote
          ...ProjectStatistics
          ...ProjectText
          ...ProjectVideo
        }
      }
    }
  }
`;
